<template>
    <div class="grid">
        <div class="col-12">
            <div class="card main-card">


                <Dialog header="Create a new objective" v-model:visible="display" :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" :modal="true">
                    <Textarea v-model="desc" placeholder="Description of your objective" :autoResize="true" rows="5" style="width:100%" />
                    <template #footer>
                        <Button label="Save" @click="save" icon="pi pi-check" class="p-button-secondary"/>
                    </template>
                </Dialog>


                <div class="flex justify-center mb-4">
                    <Button icon="pi pi-plus" label="Add new objective" class="p-button-rounded p-button-text" @click="open" />
                </div>


                <Toast/>
                <DataTable
                    ref="dt"
                    :value="data"
                    dataKey="id"
                    :paginator="true"
                    :rows="25"
                    :filters="filters"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25,50]"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    sortField="metric"
                    :sortOrder=-1
                    :loading="loading1"
                    responsiveLayout="scroll"
                >
                    <template #empty>
                        No objectives yet.
                    </template>

                    <Column
                      headerStyle="min-width: 4rem; text-align: center"
                      bodyStyle="text-align: center; overflow: visible"
                    >
                      <template #body="slotProps">
                        <Button
                          type="button"
                          icon="pi pi-trash"
                          class="p-button-rounded p-button-secondary p-button-outlined p-button-sm"
                          v-on:click="deleteObj(slotProps.data.id)"
                        ></Button>
                      </template>
                    </Column>

                    <Column field="description" header="Description" :sortable="false" >
                        <template #body="slotProps">
                            {{slotProps.data.desc}}
                        </template>
                    </Column>
                    <Column field="progress" header="Progress" :sortable="false" >
                        <template #body="slotProps">
                            <Knob v-on:change="knobChange(slotProps.data, $event)" v-model="slotProps.data.progress" :step="1" :min="0" :max="100" valueTemplate="{value}%" />
                        </template>
                    </Column>
                    <Column field="ludi_amount" header="Coach Accepted?" :sortable="false" >
                        <template #body="slotProps">
                            <Button v-if="slotProps.data.accepted == 1" disabled icon="pi pi-check" class="p-button-rounded p-button-text mr-2 mb-2" />
                            <Button v-else disabled icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text mr-2 mb-2" />
                        </template>
                    </Column>

                </DataTable>
            </div>
        </div>
    </div>

</template>

<script>
import UserService from '../service/UserService';
import {debounce} from '../util'

export default {
    data() {
        return {
            display: false,
            data: null,
            filters: {},
            windowWidth: window.innerWidth,
            loading1: true,
            desc: "",
            timeoutID: null
        }
    },
    userService: null,
    created() {
        this.userService = new UserService();
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.loading1 = true;
            this.userService.getObjectives().then(data => {
                this.loading1 = false;
                this.data = data;
            });
        },
        open() {
            this.display = true;
        },
        close() {
            this.display = false;
        },
        save() {
            this.close();
            this.userService.createObjective({"desc": this.desc}).then((d) => {
                console.log(d);
                this.refresh();
            });
        },
        deleteObj(id_) {
            console.log("Deleting ", id_);
            this.userService.delObjective(id_).then((d) => {
                console.log(d);
                this.refresh();
            });
        },
        knobChange(data, event) {
            // TODO DEBOUNCE THIS!
            clearTimeout(this.timeoutID)
            var args = arguments;
            var that = this;
            this.timeoutID = setTimeout(function () {
                console.log(event);
                that.userService.updateObjective({
                    "id_": data.id,
                    "desc": data.desc,
                    "progress": event
                }).then((d) => {
                    console.log(d);
                    that.refresh();
                });
            }, 200);
        }
    }
}
</script>

<style lang="scss">
@media (max-width: 450px) {
    .layout-wrapper .layout-main-container {
        padding-left: 0rem!important;
    }
    .layout-main-container {
        padding: 7rem 0rem 2rem 0rem!important;
    }
}
</style>
<style scoped lang="scss">
@media (max-width: 450px) {
    .grid {
        margin: 0px!important;
    }
    .main-card {
        padding: 0px!important;
    }
    .xlm_token {
        padding: 1rem;
    }
}
img {
    width: 32px;
    border-radius: 30px;
}
</style>
<style>
.p-avatar.p-avatar-image {
    overflow: hidden;
}
.p-avatar img {
    height: 40px!important;
    width: auto!important;
}
.p-avatar-custom {
    overflow: hidden;
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    /* border: 1px solid black; */

    background-size: cover;
    background-position: center;
}
.p-avatar-custom img {
    width: 100%;
}
.textarea {
    white-space: pre-wrap;
}
.p-rating {
    min-width: 110px;
}
.xlm_token {
    overflow: scroll;
}
</style>
